<template>
  <div>

  </div>
</template>


<script>
export default {
  name: 'favoritos'
};
</script>
